
import Vue, { PropType } from "vue";
import { NolaAnalytics, NolaItemId, NolaContentType } from "@/lib/utils/analytics";
import { format } from "date-fns";
import NolaNovelListItem from "@/components/molecules/NolaNovelListItem.vue";
import { Manuscript, Novel, User } from "@/lib/models";
import { showNotifyDialog } from "@/lib/dialog";
import { Dialog } from "@/lib/utils";
import PenNameSaveDialog from "@/components/ui/PenNameSaveDialog.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { NolaNovelListItem },
  props: {
    novel: Object as PropType<Novel>,
    isFirst: Boolean,
  },
  data() {
    return {
      nolaAnalytics: new NolaAnalytics(this.$gtm, this.$store),
    };
  },
  computed: {
    title() {
      return this.novel.title;
    },
    updatedAt() {
      const { updatedAtLatestDataInNovel, updatedAt } = this.novel;
      return format(updatedAtLatestDataInNovel || updatedAt!, "YYYY/MM/DD");
    },
    createdAt() {
      const { createdAt } = this.novel;
      return format(createdAt!, "YYYY/MM/DD");
    },
    manuscripts() {
      return this.$store.getters["manuscriptModule/manuscriptList"];
    },
    hasDisplayedPenNameSavePopup() {
      return this.$store.getters["generalModule/hasDisplayedPenNameSavePopup"];
    },
    isUserNameEmpty() {
      const user: User = this.$store.getters["userModule/user"];

      return !user.name;
    },
  },
  methods: {
    async onClickAssociate() {
      const { novel } = this;
      const { dispatch } = this.$store;

      await dispatch("manuscriptModule/initialize", {
        novelId: novel.novelId,
      });
      // 原稿がない作品の場合はエージェントに追加できない
      if (this.manuscripts.length === 0) {
        await showNotifyDialog({
          title: "エラー",
          content: "原稿がない作品はエージェントに提出できません。",
        });
      } else {
        await dispatch("novelModule/requestNolaAgentForNovel", { novelId: novel.novelId });
        this.$notify({
          title: "提出しました",
          type: "success",
        });

        this.nolaAnalytics.logButtonEvent(NolaItemId.AgentLink, this.novel.novelId);

        // ポップアップ表示（初回かつ、ペンネーム未設定の場合）
        if (!this.hasDisplayedPenNameSavePopup && this.isUserNameEmpty) {
          const penNameSaveDialog = new Dialog(PenNameSaveDialog);
          penNameSaveDialog.show();

          // 次回以降ポップアップを非表示にする
          await this.$store.dispatch("generalModule/setDisplayedPenNameSavePopup", true);

          this.nolaAnalytics.logViewItemEvent(NolaItemId.PenNameSavePopup, NolaContentType.Popup);
        }
      }
    },
  },
});

interface Props {
  novel: Novel;
  isFirst: boolean;
}

interface Data {
  nolaAnalytics: NolaAnalytics;
}

interface Computed {
  title: string;
  updatedAt: string;
  createdAt: string;
  manuscripts: Manuscript[];
  hasDisplayedPenNameSavePopup: boolean;
  isUserNameEmpty: boolean;
}

interface Methods {
  onClickAssociate(): void;
}
