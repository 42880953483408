
import Vue from "vue";
import { NolaAnalytics, NolaItemId } from "@/lib/utils/analytics";

export default Vue.extend({
  data() {
    return {
      penName: "",
      nolaAnalytics: new NolaAnalytics(this.$gtm, this.$store),
    };
  },
  computed: {
    isButtonDisabled() {
      // ペンネームが未入力の場合はボタンを無効化
      return this.penName.trim() === "";
    },
  },
  methods: {
    onSave() {
      this.$store.dispatch("userModule/updateUser", this.penName);
      this.$notify({
        title: "ペンネームを保存しました",
        type: "success",
      });

      this.nolaAnalytics.logButtonEvent(NolaItemId.PenNameSavePopupCompleteButton);

      this.$close(true);
    },
    onNegativeClick() {
      this.nolaAnalytics.logButtonEvent(NolaItemId.PenNameSavePopupCancelButton);

      this.$close(false);
    },
  },
});
