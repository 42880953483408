var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "dialog-mask" }, [
    _c("div", { staticClass: "dialog-content" }, [
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _c("div", { staticClass: "dialog-input" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.penName,
              expression: "penName",
            },
          ],
          staticClass: "text-input",
          attrs: {
            type: "text",
            placeholder: "ペンネームを入力する",
            maxlength: 100,
          },
          domProps: { value: _vm.penName },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.penName = $event.target.value
            },
          },
        }),
      ]),
      _c("p", { staticClass: "dialog-note" }, [
        _vm._v("※ マイページからいつでも変更が可能です。"),
      ]),
      _c("footer", [
        _c(
          "button",
          {
            staticClass: "button primary",
            class: { disabled: _vm.isButtonDisabled },
            attrs: { disabled: _vm.isButtonDisabled },
            on: { click: _vm.onSave },
          },
          [_vm._v(" 保存して完了 ")]
        ),
        _c(
          "a",
          { staticClass: "cancel-text", on: { click: _vm.onNegativeClick } },
          [_vm._v("キャンセル")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dialog-image-wrapper" }, [
      _c("img", {
        staticClass: "envelope-image",
        attrs: { src: require("@/assets/img/agent/envelope.png") },
      }),
      _c("img", {
        staticClass: "check-icon",
        attrs: { src: require("@/assets/img/agent/check_icon.png") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h3", { staticClass: "dialog-title" }, [
      _vm._v("Nolaエージェントへ作品の"),
      _c("br"),
      _vm._v("ご連携ありがとうございます！"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "dialog-description" }, [
      _vm._v(" 機能の利用にあたり、"),
      _c("strong", { staticClass: "highlight" }, [_vm._v("『ペンネーム』")]),
      _vm._v("を"),
      _c("br"),
      _vm._v("入力して保存をお願いします。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }